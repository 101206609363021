<template>
      <v-app class>
        <div>
          <v-alert
            v-model="alert"
            border="left"
            dark
            dismissible
            class="alert"
            :class="{'alert-error':alertType=='error', 'alert-success':alertType=='success'}"
          >
    {{alertMessage}}
    </v-alert>
        </div>
        <div class="row">
          <div class="col-md-12 ml-auto mr-auto">
            <v-container>
              <div class="mb-8 mt-8 text-center">
                <h1>List of  Personnel</h1>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <v-text-field
                    v-model="searchPersonnel"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                  ></v-text-field>
                </div>
                <div v-if="userRole !== appRoles.LCE" class="col-md-6 mt-2">
                  <SelectField
                    :items="contractEmployers"
                    v-model="contractEmployerId"
                    :label="'Contractor Employer'"
                    :itemText="'name'"
                    :itemValue="'id'"
                    @onChange="contractEmployerChange"
                  />
                </div>
              </div>
             <v-data-table
              loading-text="Loading personnel. Please wait...."
              :loading="isLoading || allLoading < 7"
              :headers= 'headers'
              :items="employeeList"
              :search="searchPersonnel"
              item-key="id"
             >
              <!-- <template slot="items" slot-scope="props">
                <tr>
                  <td>{{ props.item.firstName}}</td>
                  <td>{{ props.item.lastName}}</td>
                </tr>
              </template> -->
              <template v-slot:item.actions="{ item }">
                <div class="d-flex">
                  <Button :btnType="'Submit'" :disabled="allLoading < 7" :label="'Edit'" @onClick="edit(item)" class="mr-2"/>
                 <!-- <Button :btnType="'Cancel'" :label="'Delete'" @onClick="openDeleteModal(item)" /> -->
                </div>
              </template>
              <template v-slot:item.lastModifiedDate="{ item }">
                {{item.lastModifiedDate | formatDate}}
              </template>

             </v-data-table>

              <!-- Dialog for edit  -->
              <!-- <Dialog ref="editModal" :title="'Edit'" :width="600">
                <v-row>
                  <TextField v-model="formData.name" :label="'Document Name'"/>
                </v-row>
                <template v-slot:footer>
                  <Button :btnType="'Submit'" :label="'Update'" @onClick="update" class="mr-2"/>
                  <Button :btnType="'Cancel'" :label="'Cancel'" @onClick="cancel('editModal')" />
                </template>
              </Dialog> -->

              <!-- confirm delete modal  -->
              <!-- <Dialog ref="deleteModal" :title="'Are you sure you want to delete this personnel'" :width="400">
                <v-row>
                  <p>{{formData.name}}</p>
                </v-row>
                <template v-slot:footer>
                  <Button :btnType="'Submit'" :label="'Yes'" @onClick="deletePersonnel" class="mr-2"/>
                  <Button :btnType="'Cancel'" :label="'No'" @onClick="cancel('deleteModal')" />
                </template>
              </Dialog> -->
            </v-container>
          </div>
        </div>
      </v-app>
</template>
<script>

import SelectField from '@/components/ui/form/SelectField.vue'
// import TextField from '@/components/ui/form/TextField.vue'
import Button from '@/components/ui/form/Button.vue'

// import Dialog from '@/components/ui/Dialog.vue'
import { FETCH_DEPARTMENT, FETCH_JOBTITLE, FETCH_WORKAREA, FETCH_ROTATION, FETCH_LOCATION, FETCH_CHARGE_CODE, FETCH_ORGANISATION } from '@/store/action-type'
import { userService, employeeService, contractEmployerService } from '@/services'
import Roles from '@/utils/roles'

export default {
  components: {
    SelectField,
    // TextField,
    Button
    // Dialog
  },
  data () {
    return {
      search: '',
      userRole: '',
      appRoles: {},
      isLoading: false,
      formData: {
        name: '',
        id: ''
      },
      contractEmployerId: 0,
      employeeList: [],
      contractEmployers: [],
      personnel: {},
      searchPersonnel: '',
      valid: false,
      isSavingUser: false,
      alertMessage: '',
      allLoading: 0,
      alert: false,
      alertType: '',
      headers: [
        //{         
        //  fixed: true,
        //  width: '100px',
        //  sortable: false
        //},
        {
          text: 'Prnumber',
          value: 'prnumber',
          fixed: true,
          width: '100px'
        },
        {
          text: 'Charge Code',
          value: 'chargeCode'
        },
        {
          text: 'Job Title',
          value: 'jobTitle.name'
        },
        {
          text: 'First Name',
          value: 'firstName'
        },
        {
          text: 'Last Name',
          value: 'lastName'
        },
        {
          text: 'Email',
          value: 'email'
        },
        {
          text: 'Location',
          value: 'location.name'
        },
        {
          text: 'Department',
          value: 'department.name'
        },
        {
          text: 'Status',
          value: 'employeeStatus'
        },
        {
          text: 'Modified By',
          value: 'modifiedBy',
          sortable: false
        },
        {
          text: 'Date Modified',
          value: 'lastModifiedDate'
        },
        {
          text: 'Actions',
          value: 'actions',
          fixed: true,
          sortable: false
        }
      ]
    }
  },
  computed: {
    // employees () {
    //   // this.employeeList = this.$store.getters.employees
    //   return this.$store.getters.employees
    // }
  },
  methods: {
    showAlertMessage (message, type) {
      this.alertMessage = message
      this.alert = true
      this.alertType = type
    },
    edit (item) {
      this.$router.push(`/personnel-data/edit/${item.id}`)
    },
    openDeleteModal (item) {
      this.personnel = item
      this.$refs.deleteModal.toggleModal()
      this.formData.name = `${item.firstName}, ${item.lastName} ${item.middleName}`
    },
    resetForm () {
      this.formData.name = ''
      this.formData.id = ''
    },
    cancel (modal) {
      if (modal === 'editModal') {
        this.$refs.editModal.toggleModal()
      }
      if (modal === 'deleteModal') {
        this.$refs.deleteModal.toggleModal()
      }
    },
    contractEmployerChange (item) {
      this.employeeList = []
      this.isLoading = true
      if (item === 0.1) {
        this.getAllEmployee()
        return
      }
      employeeService.getEmployeesByEmployerId(item).then(result => {
        this.employeeList = result.data.items
        this.isLoading = false
      }).catch(() => {
        this.isLoading = false
      })
    },
    getAllEmployee () {
      employeeService.getAllEmployees().then(result => {
        this.employeeList = result.data.items
        this.isLoading = false
      }).catch(() => {
        this.isLoading = false
      })
    }
  },
  mounted () {
    this.isLoading = true
    this.appRoles = Roles

    userService.getCurrentUser().then(result => {
      this.userRole = result.data.roleName
    })

    contractEmployerService.getAllContractEmployers().then((result) => {
      this.contractEmployers = result.data.items
      this.contractEmployers.unshift({ 'id': 0.1, 'name': 'All' })
    })

    this.$store
      .dispatch(FETCH_DEPARTMENT)
      .then(() => {
        this.allLoading = this.allLoading + 1
      })
      .catch((error) => {
        console.log(error)
      })
    this.$store
      .dispatch(FETCH_JOBTITLE)
      .then(() => {
        this.allLoading = this.allLoading + 1
      })
      .catch((error) => {
        console.log(error)
      })
    this.$store
      .dispatch(FETCH_WORKAREA)
      .then(() => {
        this.allLoading = this.allLoading + 1
      })
      .catch((error) => {
        console.log(error)
      })
    this.$store
      .dispatch(FETCH_LOCATION)
      .then(() => {
        this.allLoading = this.allLoading + 1
      })
      .catch((error) => {
        console.log(error)
      })

    this.$store
      .dispatch(FETCH_ROTATION)
      .then(() => {
        this.allLoading = this.allLoading + 1
      })
      .catch((error) => {
        console.log(error)
      })

    this.$store
      .dispatch(FETCH_CHARGE_CODE)
      .then(() => {
        this.allLoading = this.allLoading + 1
      })
      .catch((error) => {
        console.log(error)
      })
    this.$store
      .dispatch(FETCH_ORGANISATION)
      .then(() => {
        this.allLoading = this.allLoading + 1
      })
      .catch((error) => {
        console.log(error)
      })

    this.getAllEmployee()

    // this.$store
    //   .dispatch(FETCH_EMPLOYEES)
    //   .then(data => {
    //     console.clear()
    //     console.log('data:', data)
    //   })
    //   .catch(() => {
    //     // if (error) {
    //     //   this.showAlertMessage('Unable to display departments', 'error')
    //     // }
    //   })
  }
}
</script>
<style scoped>
.search-btn {
  background-color: #e6e3e3;
  height: 40px;
  border-radius: 3px !important;
}
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
.alert {
  position: fixed;
  z-index: 9999;
  top: 5px;
  min-width: 50%;
}
.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
</style>
